import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import IndexLayout from '../layouts';
import { inner, outer, SiteMain, SiteHeader } from '../styles/shared';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import Chart from "react-google-charts";

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

const ChartArea = styled.div`
  margin-top: 30px;
`;

const data = [
  [
    { type: 'string', label: 'Task ID' },
    { type: 'string', label: 'Task Name' },
    { type: 'string', label: 'Resource' },
    { type: 'date', label: 'Start Date' },
    { type: 'date', label: 'End Date' },
    { type: 'number', label: 'Duration' },
    { type: 'number', label: 'Percent Complete' },
    { type: 'string', label: 'Dependencies' },
  ],
  [
    'ドローンスクール（4日間）',
    'ドローンスクール（4日間）',
    'Q活動',
    new Date(2019, 8, 15),
    new Date(2019, 11, 1),
    null,
    100,
    null,
  ],
  [
    'Cesiumと3D座標計算の学習',
    'Cesiumと3D座標計算の学習',
    '地理情報プログラミング',
    new Date(2019, 9, 1),
    new Date(2019, 10, 4),
    null,
    100,
    null,
  ],
  [
    'Dronecode（ソフトウェア）の学習',
    'Dronecode（ソフトウェア）の学習',
    'ドローンプログラミング',
    new Date(2019, 9, 1),
    new Date(2019, 10, 26),
    null,
    100,
    null,
  ],
  [
    'ドローンレースのボランティア',
    'ドローンレースのボランティア',
    'Q活動',
    new Date(2019, 10, 1),
    new Date(2019, 10, 3),
    null,
    100,
    null,
  ],
  [
    'Dronecode（ハードウェア）の学習',
    'Dronecode（ハードウェア）の学習',
    'ドローン機体製作',
    new Date(2019, 10, 26),
    new Date(2019, 11, 15),
    null,
    100,
    null,
  ],
  [
    'ドローンWebサービス開発',
    'ドローンWebサービス開発',
    'オリジナルプロダクト開発',
    new Date(2020, 0, 1),
    new Date(2020, 1, 24),
    null,
    100,
    null,
  ],
  [
    'WebRTCの学習',
    'WebRTCの学習',
    'マルチメディアプログラミング',
    new Date(2020, 1, 1),
    new Date(2020, 1, 24),
    null,
    100,
    null,
  ],
  [
    'ドローン業界の世界動向調査',
    'ドローン業界の世界動向調査',
    '動向調査',
    new Date(2019, 11, 15),
    new Date(2020, 3, 1),
    null,
    100,
    null,
  ],
  [
    'ReactとNode.jsの学習',
    'ReactとNode.jsの学習',
    'フロントエンドプログラミング',
    new Date(2020, 3, 1),
    new Date(2020, 5, 31),
    null,
    100,
    null,
  ],
  [
    '英語（リーディング）の学習',
    '英語（リーディング）の学習',
    '英語',
    new Date(2020, 4, 1),
    new Date(2020, 4, 30),
    null,
    100,
    null,
  ],
  [
    'アジャイルソフトウェア開発の学習',
    'アジャイルソフトウェア開発の学習',
    'ソフトウェア開発',
    new Date(2020, 6, 1),
    new Date(2020, 6, 30),
    null,
    100,
    null,
  ],
  [
    'ドローンWebサービス開発(ver.2)',
    'ドローンWebサービス開発(ver.2)',
    'オリジナルプロダクト開発',
    new Date(2020, 7, 1),
    new Date(2020, 7, 31),
    null,
    100,
    null,
  ],
];

const PrivacyPolicy: React.FC = () => {
  return (
    <IndexLayout>
    <Helmet>
      <title>個人学習記録</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page">
              <h2>
                個人学習記録
              </h2>
              <p>
                以下のチャートは、ドローンのソフトウェアエンジニアとして本腰を入れて勉強を始めたタイミングから、<br/>
                現在までの主要な取り組みをガントチャートで表現したものです。<br/>
                これらの取り組みの記録は、ポートフォリオにリンクしている<a href="http://tomofiles.hatenablog.com/">はてなブログ</a>をご覧ください。
              </p>
              <ChartArea>
                <Chart
                  width={'100%'}
                  height={'400px'}
                  chartType="Gantt"
                  loader={<div>Loading Chart</div>}
                  data={data}
                  options={{
                    height: 400,
                    gantt: {
                      trackHeight: 30,
                    },
                  }}
                  rootProps={{ 'data-testid': '2' }}
                />
              </ChartArea>
            </article>
            <article className="post page">
              <h2>
                GitHub Grass Graph
              </h2>
              <img src="https://grass-graph.moshimo.works/images/tomofiles.png" />
              <div style={{width: "100%"}} />
              <a href="https://grass-graph.moshimo.works/" >
                powered by
              </a>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default PrivacyPolicy;
